import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const PageContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.9;
  background: linear-gradient(0deg, rgba(214,221,228,0.45) 0%, rgba(191,192,229,0.23) 100%);
}
`
const Divider = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: ${props => props.theme.colors.green};
`
const ContactForm = styled.form`
  width: 30rem;
  height: 42rem;
  display: flex;
  flex-direction: column;
  &::before {
    content: '';
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${props => (props.overlay ? '.8' : '0')};
    visibility: ${props => (props.overlay ? 'visible' : 'hidden')};
  }
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 19rem;
  }
`
const HeaderContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem auto 3rem;
`
const Title = styled.h1`
  color: #2F3133;
  font-family: "Apple SD Gothic Neo";
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;
  margin: 0 auto 2rem;
`
const Description = styled.h2`
  height: 3rem;
  width: 30rem;
  color: #000000;
  font-family: "Apple SD Gothic Neo";
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;
  word-break: keep-all;
  a {
    color: ${props => props.theme.colors.green};
    font-family: "Apple SD Gothic Neo";
    font-size: 1rem;
    font-weight: 600;
    word-break: keep-all;
    text-decoration: none;
  }
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 21rem;
  }
}
`
const InputTypes = styled.h2`
  color: #2F3133;
  font-family: "Apple SD Gothic Neo";
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
`
const Name = styled.input`
  box-sizing: border-box;
  padding: 1rem;
  height: 2.5rem;
  width: 18rem;
  font-size: 1rem;
  border: 1px solid #DBDBDB;
  background-color: #FFFFFF;
  margin: 0.5rem 0 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 15rem;
  }
`
const Email = styled.input`
  box-sizing: border-box;
  padding: 1rem;
  height: 2.5rem;
  width: 18rem;
  font-size: 1rem;
  border: 1px solid #DBDBDB;
  background-color: #FFFFFF;
  margin: 0.5rem 0 0;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 15rem;
  }
`
const SubjectLine = styled.input`
  box-sizing: border-box;
  padding: 1rem;
  height: 2.5rem;
  width: 30rem;
  font-size: 1rem;
  border: 1px solid #DBDBDB;
  background-color: #FFFFFF;
  margin: 0.5rem 0 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 100%;
  }
`
const Message = styled.textarea`
  box-sizing: border-box;
  padding: 1rem;
  text-align: top;
  height: 10rem;
  width: 30rem;
  font-size: 1rem;
  border: 1px solid #DBDBDB;
  background-color: #FFFFFF;
  margin: 0.5rem 0 1rem;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 100%;
  }
`
const SendButton = styled.input`
  margin: 2rem auto auto;
  height: 3rem;
  width: 9.5rem;
  border-radius: 26px;
  background-color: ${props => props.theme.colors.green};
  font-size: 1rem;
  font-weight: 500;
  color: white;
`
const Modal = styled.div`
  background: white;
  padding: 4rem;
  border-radius: 2px;
  position: fixed;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  p {
    line-height: 1.6;

  }
`

const ModalButton = styled.div`
  background: ${props => props.theme.colors.green};
  font-size: 0.75rem;
  display: inline-block;
  margin: 2rem auto auto;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s;
  margin: 2rem auto auto;
  height: 1.75rem;
  width: 5rem;
  border-radius: 26px;
  font-weight: 500;
  color: white;
  padding: 0.5rem 0 0;
  text-align: center;
`
const EmailValidation = styled.div`
  font-size: 0.75rem;
  color: red;
  margin: 0.5rem 0 1rem;
`
const TextLink = styled(Link)`
  color: ${props => props.theme.colors.green};
  font-family: "Apple SD Gothic Neo";
  font-size: 1rem;
  font-weight: 600;
  word-break: keep-all;
  text-decoration: none;
`

const OrangeFont = { color: '#F26921'}

const hostUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:9000' : ''
const requestMode = process.env.NODE_ENV === 'development' ? 'no-cors' : 'same-origin'
const alertMessage = "서버 오류로 인하여 보내지지 않았습니다. joyfulchurchca@gmail.com로 이메일을 보내주시기 바랍니다."

const ServicesContact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subjectLine, setSubjectLine] = useState('');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [emailValidation, setEmailValidation] = useState('');
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

  const handleSubmit = () => {
    if (!pattern.test(email)) {
      setEmailValidation('* 올바른 이메일 형식이 아닙니다');
    } else {
      const payload = {
        sender: name,
        emailAddr: email,
        subject: subjectLine,
        content: message
      };

      fetch(`${hostUrl}/.netlify/functions/sendemail`, {
        method: 'POST',
        mode: requestMode,
        body: JSON.stringify(payload),
      })
        .then(response => {
          if (response.ok || requestMode === 'no-cors') {
            handleSuccess()
          } else {
            alert(alertMessage)
          }
        })
        .catch(error => alert(alertMessage))
    }
    event.preventDefault();
  }

  const handleSuccess = () => {
    setShowModal(true);
  }

  const closeModal = () => {
    setName('');
    setEmail('');
    setSubjectLine('');
    setMessage('');
    setShowModal(false);
    setEmailValidation('');
  }

  return (
    <PageContainer>
      <Divider />
      <HeaderContainer>
        <Title>
          {`문의하기`}
        </Title>
        <Description>
          {`문의사항이나 질문이 있을 경우 `}<TextLink to="/info/about-us#contacts">{`각 부서별 담당자 연락처`}</TextLink>{`를 통해 연락을 주시거나 아래 form을 사용해주세요.`}
        </Description>
      </HeaderContainer>
      <ContactForm onSubmit={handleSubmit} overlay={showModal ? 1 : 0}>
        <InputTypes>
          {`이름 (Full Name) `}<span style={OrangeFont}>{`*`}</span>
        </InputTypes>
        <Name
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />
        <InputTypes>
          {`Email 주소 `}<span style={OrangeFont}>{`*`}</span>
        </InputTypes>
        <Email
          type="text"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
        <EmailValidation>
          {emailValidation}
        </EmailValidation>
        <InputTypes>
          {`제목 (Subject Line) `}<span style={OrangeFont}>{`*`}</span>
        </InputTypes>
        <SubjectLine
          type="text"
          value={subjectLine}
          onChange={e => setSubjectLine(e.target.value)}
          required
        />
        <InputTypes>
          {`메세지 (Message) `}<span style={OrangeFont}>{`*`}</span>
        </InputTypes>
        <Message
          type="text"
          value={message}
          onChange={e => setMessage(e.target.value)}
          required
        />
        <SendButton
          type="submit" value="Send"
        />

        { showModal ?
          <Modal>
            <p>
              감사합니다.
            </p>
            <ModalButton onClick={closeModal}>Okay</ModalButton>
          </Modal>
          : null
        }
      </ContactForm>
    </PageContainer>
  )
}

export default ServicesContact
