import React from 'react'
import Layout from '../../components/Layout'
import ServicesContact from '../../components/ServicesContact'
import SEO from '../../components/SEO'

const Contact = () => {
    return (
        <Layout>
            <SEO title="문의 | 안내 - 죠이플 교회" description="Contact form for site visitors" />
            <ServicesContact />
        </Layout>
    )
}

export default Contact
